<template>
    <div class="mail">
        <a class="mail" href="mailto:band@abekamui.de">Kontakt</a>
    </div>
</template>

<script>
    export default {
        name: 'Mail',
    }
</script>