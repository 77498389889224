<template>
  <div id="app">
    <Header/>
    <Home/>
    <Mail/>
    <Footer/>
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Mail from './components/Mail.vue'

export default {
  name: 'App',
  components: {
    Home,
    Header,
    Footer,
    Mail
  }
}
</script>

<style lang="scss">
  @import "./assets/variables.scss";
  @import "./assets/fonts.scss";

  html, body {
    height: 100%;
    margin: 0;
    overflow: hidden;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: black;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 767px){
      justify-content: center;
    }
  }

  .header{

    #logo{
      width:350px;
      padding: 10vw 10vw 0 10vw;
      @media screen and (min-width: 767px){
        padding: 0 10vw;
      }
      max-width: 80vw;
      max-height: calc(90vh - 250px);
      path{
        fill:#ffffff;
        stroke: #ffffff;
      }
    }
  }

  body{
    background: black;
    font-family: "Agency Bold",sans-serif;
  }
  a{
    color: #ffffff;
  }

  .footer{
    display: flex;
    justify-content: center;

    .social {
      display: flex;
      flex-direction: row;

      svg {
        height: 35px;
        margin: 15px;
        width: auto;
      }

      position: fixed;
      bottom: 0;
    }
  }
  .mail{
    text-transform: uppercase;
    text-decoration: none;
    font-size: 2rem;
    margin-top: 35px;
    &:hover{
      text-decoration: underline;
    }
  }

</style>